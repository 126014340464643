

@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@400;500;700&display=swap');
:root {
  --font-family: 'Roboto Serif', serif;
}
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
  font-family: var(--font-family);
  background: #FFF;
}

.content-body {
  min-height: 500px;
  padding: 10px 160px;
}
/* CSS */
/* Hide number input arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  text-align: center; /* Center-align the text */

}

/* Hide number input arrows for all other browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  text-align: center; /* Center-align the text */

}
input[type="number"].text-center {
  text-align: center; /* Center-align the text */
}
.zen-header {
  background-color: #F0FCF8 !important;
  height: 100px;
}

.slide-image {
  width: 100% !important;
  height: 292px !important;
}
.zen-group-detail-image{
  width: 100% !important;
  height: 210px !important;
  border-radius: 12% !important;
  background: lightgray 50% !important;

}
.zen-group-map-image{
  width: 100% !important;
  height: 320px !important;
  border-radius: 8% !important;
  background: lightgray 50% !important;

}
.zen-card {
  margin-bottom: 3%;
  border-radius: 12px !important;
  background-color: #F4F4F4 !important;
}

.zen-card .card-body {
  min-height: 100px !important;
}

.zen-page-heading {
  display: flex;
  /* height: 90px; */
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.zen-page-heading h1 h2 h3 h4{
  line-height: 30px !important;
}
.zen-page-heading h1 {
  color: #0000B0;
  font-family: var(--font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 20px; */
  /* 50% */
  letter-spacing: 0.1px;
}
.zen-page-heading h2 {
  color: #0000B0;
  font-family: var(--font-family);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 20px; */
  /* 50% */
  letter-spacing: 0.1px;
}
.zen-page-heading h3 {
  color: #0000B0 !important;
  font-family: var(--font-family);
  font-family: Roboto Serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}
.zen-page-heading h4 {
  color: #0000B0 !important;
  font-family: var(--font-family);
  font-family: Roboto Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}
.zen-success-heading h4{
  color: #06AC0D !important;
  font-family: var(--font-family);
  font-family: Roboto Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.zen-card-heading {
  /* display: flex;   */
  color: #0000B0 !important;
  font-family: var(--font-family);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  /* line-height: normal; */
}

.zen-card-header-icon {
  border-radius: 8px !important;
  border: 1px solid var(--m-3-sys-light-outline, #79747E) !important;
  background: #FFF3F4 !important;
  color: var(--m-3-sys-light-on-surface-variant, #49454F);
  justify-content: center !important;
  align-items: center !important;
  font-family: var(--font-family);
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  /* 142.857% */
  letter-spacing: 0.1px !important;
  height: 32px !important;
}

.zen-filter {
  display: flex;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.zen-filter-icon {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.zen-filter-text {
  color: #292F33 !important;
  /* text-align: center; */
  font-family: var(--font-family);
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  /* 87.5% */
}
.zen-filter-text.active {
  color: #243E8F !important; /* Text color for active tab */

  /* 87.5% */
}
.zen-tag-text {
  color: #292F33 !important;
  /* text-align: center; */
  font-family: var(--font-family);
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  /* 87.5% */
}
.zen-tag-text.active {
  color: #243E8F !important; /* Text color for active tab */

  /* 87.5% */
}
.zen-tag-tab {
  /* display: flex;
  width: auto;
  height: 56px;
  /* justify-content: space-between !important; */
  /* padding: 20px !important; */
  /* align-items: center; */
  /* gap: 26px !important; */
  /* flex-shrink: 0;  */
  border-radius: 8px !important; 
  background: #FAFAFA !important;
  padding: 5px 5px 5px !important;
}
.zen-tag-tab.active{
  /* display: flex;
  width: auto;
  height: 56px;
  /* justify-content: space-between !important; */
  /* padding: 20px !important; */
  /* align-items: center; */
  /* gap: 26px !important; */
  /* flex-shrink: 0;  */
  border-radius: 8px !important;
  border: 1px solid #243E8F !important; 
  background: linear-gradient(0deg, #F0FCF8, #F0FCF8) !important;
  color: #243E8F !important; /* Text color for active tab */
}
.zen-filter-tab {
  /* display: flex;
  width: auto;
  height: 56px;
  /* justify-content: space-between !important; */
  /* padding: 20px !important; */
  /* align-items: center; */
  /* gap: 26px !important; */
  /* flex-shrink: 0;  */
  border-radius: 16px !important; 
  background: #FAFAFA !important;
  padding: 10px 20px 10px !important;
}
.zen-filter-tab.active{
  /* display: flex;
  width: auto;
  height: 56px;
  /* justify-content: space-between !important; */
  /* padding: 20px !important; */
  /* align-items: center; */
  /* gap: 26px !important; */
  /* flex-shrink: 0;  */
  border-radius: 16px !important;
  border: 1px solid #243E8F !important; 
  background: linear-gradient(0deg, #F0FCF8, #F0FCF8) !important;
  color: #243E8F !important; /* Text color for active tab */
}

.zen-book-btn{
  padding: 10px 70px 10px !important;
}
/* Define the shimmer animation */
@keyframes shimmerAnimation {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
}

/* Style the shimmer image */
.shimmer-image {
  height: 200px;
  background-color: #ddd;
  background-image: linear-gradient(90deg,
      #ddd,
      #f5f5f5,
      #ddd);
  /* Gradient for shimmer effect */
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: shimmerAnimation 1s infinite linear;
}

.shimmer-banner {
  height: 100px;
  background-color: #ddd;
  background-image: linear-gradient(90deg,
      #ddd,
      #f5f5f5,
      #ddd);
  /* Gradient for shimmer effect */
  background-size: 100px 100%;
  background-repeat: no-repeat;
  animation: shimmerAnimation 1s infinite linear;
}

/* Style the shimmer content */
.shimmer-content {
  margin-top: 10px;
}

/* Style the shimmer title */
.shimmer-title {
  height: 20px;
  background-color: #ddd;
  background-image: linear-gradient(90deg,
      #ddd,
      #f5f5f5,
      #ddd);
  /* Gradient for shimmer effect */
  background-size: 20px 100%;
  background-repeat: no-repeat;
  animation: shimmerAnimation 1s infinite linear;
}

/* Style the shimmer description */
.shimmer-description {
  height: 20px;
  background-color: #ddd;
  background-image: linear-gradient(90deg,
      #ddd,
      #f5f5f5,
      #ddd);
  /* Gradient for shimmer effect */
  background-size: 20px 100%;
  background-repeat: no-repeat;
  animation: shimmerAnimation 1s infinite linear;
}

.shimmer-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0) 100%);
  animation: shimmer 1s infinite;
}

.banner {
  width: 100%;
  height: 400px;
  border-radius: 12px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;

  /* Adjust the image's positioning within the container */
}

.react-tel-input .form-control {
  width: 100% !important;
}

/* Toggle switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin-right: 10px;
}

/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

/* Styling for active (checked) state */
input:checked+.slider {
  background-color: #007bff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked+.slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.zen-card-text {
  color: var(--text-title, #2D3141) !important;
  font-family: var(--font-family);
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.28px !important;
}

.zen-footer {
  /* display: inline-flex; */
  padding: 34px 269px 61px 238px;
  /* flex-direction: column;
  align-items: flex-start; */
  /* gap: 5px; */
}
.zen-footer-list{
  color: #49454F !important;
text-align: center !important;
font-family: var(--font-family);
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 20px !important; /* 142.857% */
letter-spacing: 0.5px !important;
}
.zen-social-icon{
  display: flex;
width: 30px;
height: 30px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 24px;
background: #949494;
margin-right: 5%;
}
.zen-footer-text{
  color: #49454F !important;
text-align: center !important;
font-family: var(--font-family);
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 20px !important; /* 142.857% */
letter-spacing: 0.1px !important;
}
.zen-footer-container{
  padding: 20px 121px 20px !important;

}
.zen-amenity-text{
color: #000 !important;
text-align: center !important;
font-family: var(--font-family) !important;
font-size: 12px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
}
.zen-ameneties{
  background: rgba(244, 244, 244, 0.50) !important;
  padding: 10px 20px 10px !important;
}
.zen-address-text{
color: var(--text-title, #2D3141);
font-family: var(--font-family) !important;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.4px;
}
.zen-location-description{
  color: #000;
text-align: justify;
font-family: Roboto Serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.booking-summary-list li{
border: none !important;
color: #292F33 !important;
font-family: Roboto Serif;
font-size: 16px !important;
font-style: normal;
font-weight: 400 !important;
line-height: .8 !important;

}
.booking-summary-list li span{
  color: #292F33 !important;
font-family: Roboto Serif;
font-size: 16px !important;
font-style: normal;
font-weight: 400 !important;
line-height: 0!important;
  }

  /* Define a style for the active button */
.btn.active {
  background-color: #007bff !important; /* Change this to your desired background color */
  color: #fff !important; /* Change this to your desired text color */
}
.custom-slide-checkbox {
  position: relative;
  width: 180px;
  height: 90px;
  background-color: #F0FCF8;
  border-radius: 10px;
  padding: 5px;
  left: 35%

}

.custom-slide-checkbox input {
  display: none;
}

.custom-slide-checkbox label {
  position: absolute;
  top: 5px;
  left: 2px;
  width: 80px;
  height: 80px;
  background-color: #243E8F;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}


/* 
  content: '';
  position: absolute;
  width: 30px;
  height: 50px;
  border-radius: 10px;
  background-color: #243E8F;
  top: 0px;
  left: 50%;
  transition: all 0.3s;
} */

.custom-slide-checkbox input:checked + label {
  left: 90px;
}

.custom-slide-checkbox input:checked + label:after {
  left: 90px;
}

.custom-slide-checkbox .lock-icon {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 36px;
  transition: all 0.3s;
  color: white;
}

.custom-slide-checkbox .unlock-icon {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 36px;
  transition: all 0.3s;
  color: white;
}

.custom-slide-checkbox.checked .lock-icon {
  left: 110px; /* Adjusted position when checked */
}

.custom-slide-checkbox.checked .unlock-icon {
  left: 110px; /* Adjusted position when checked */
}
/* PaymentForm.css */
.payment-form {
  max-width: 400px;
  /* margin: auto; */
  padding: 20px;
  /* border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.stripe-input {
  font-size: 16px;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.pay-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 149px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pay-button:hover {
  background-color: #243E8F;
}

.payment-success {
  margin-top: 20px;
  color: #4caf50;
  font-weight: bold;
}
.custom-box-shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}




